<template>
    <CCard>
        <CCardHeader class="page-header">
            <strong> {{ $t('common.menu.settings.stocks') }}</strong>
            <back-button></back-button>
        </CCardHeader>
        <CCardBody>
            <b-tabs lazy content-class="mt-3">
                <b-tab v-for="(tab,index) in tabs"
                       :key="tab.key"
                       v-if="tab.enabled"
                       :active="activeTab==tab.key"
                >
                    <template slot="title">
                        <font-awesome-icon :icon="tab.icon"></font-awesome-icon>
                        {{ tab.title }}
                        <b-badge v-if="tab.new" href="#" variant="warning">New</b-badge>
                    </template>
                    <component v-bind:is="tab.tab"></component>
                </b-tab>
            </b-tabs>
        </CCardBody>
    </CCard>
</template>

<script>
import {mapGetters} from 'vuex'

const TabStockGeneral = () => import('./stock_general')
const TabLagerTypes = () => import('./lager_types')
const TabWarehouses = () => import('./warehouses')

export default {
    name: 'SettingsStocks',
    data() {
        let tabs = [
            {
                'key': 'stockgeneral',
                'title': this.$t('settings.title.general'),
                'icon': 'dolly',
                'tab': TabStockGeneral,
                'enabled': true,
            },
            {
                'key': 'lagertypes',
                'title': this.$t('settings.title.lagerTypes'),
                'icon': 'boxes',
                'tab': TabLagerTypes,
                'enabled': true,
            },
            {
                'key': 'warehouses',
                'title': this.$t('settings.title.warehouses'),
                'icon': 'box',
                'tab': TabWarehouses,
                'enabled': true,
            },
        ]

        return {
            'activeTab': 'stockgeneral',
            'tabs': tabs,
        }
    },
    methods: {},
}
</script>